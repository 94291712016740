import React from "react"

import * as styles from "./styles.module.scss"
import { IMG } from "../../const"

const DEVELOPMENT_LIST = [
  {
    title: "Sign up form",
    text: "The User can register via email or social media profile.",
    image_name: "phone_signup",
  },
  {
    title: "Restaurants search and visits",
    text:
      "Depending on their current location, users can see the list of restaurants to go to and what to order.",
    image_name: "phone_search",
  },
  {
    title: "Own list of restaurants",
    text:
      "Each user can check information about the restaurant, check its menu, and add it to their lists.",
    image_name: "phone_list",
  },
  {
    title: "Menu ordering option",
    text:
      "The User can add options from the menu, and check information about each meal option.",
    image_name: "phone_ordering",
  },
  {
    title: "Forming bill",
    text:
      "The Users can check ordered meals and change them before payment by adding new ones or cancelling some existing ones.",
    image_name: "phone_forming",
  },
  {
    title: "Payment",
    text: "Easy payment by credit card.",
    image_name: "phone_payment",
  },
  {
    title: "Total cheque",
    text: "It is a separate form with a total bill sum, orders and payments.",
    image_name: "phone_total",
  },
]

const SoftwareDevelopment = () => {
  return (
    <section className={styles.softwareDevelopmentBlock}>
      <div className={styles.softwareDevelopmentWrapper}>
        <h2>
          Software <strong>development</strong>
        </h2>
        <p>
          Our team has developed the whole design and software for a Client.
          Among the main functionality are:
        </p>
        <div className={styles.softwareDevelopmentContent}>
          {DEVELOPMENT_LIST.map(item => (
            <div key={item.title} className={styles.item}>
              <div className={styles.text}>
                <h3>{item.title}</h3>
                <p>{item.text}</p>
              </div>
              <div>
                <picture>
                  <source
                    srcSet={`${IMG.CHEQMATE}/img/${item.image_name}_176.avif,	${IMG.CHEQMATE}/img/${item.image_name}_352.avif 2x, ${IMG.CHEQMATE}/img/${item.image_name}_528.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    srcSet={`${IMG.CHEQMATE}/img/${item.image_name}_176.webp,	${IMG.CHEQMATE}/img/${item.image_name}_352.webp 2x, ${IMG.CHEQMATE}/img/${item.image_name}_528.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    srcSet={`${IMG.CHEQMATE}/img/${item.image_name}_176.png, ${IMG.CHEQMATE}/img/${item.image_name}_352.png 2x,${IMG.CHEQMATE}/img/${item.image_name}_528.png 3x`}
                  />
                  <img
                    src={`${IMG.CHEQMATE}/img/${item.image_name}_528.png`}
                    alt={item.title}
                    width="140"
                    height="398"
                    loading="lazy"
                  />
                </picture>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SoftwareDevelopment
