import React from "react"

import * as styles from "./styles.module.scss"
import { IMG } from "../../const"

const FUNCTIONALITY_LIST = [
  {
    title: "Check-in Workflow",
    text: (
      <>
        When a user comes to the restaurant, he checks in and afterwards the
        waiter recognizes a new client and sees his receipt.
        <br />
        After the client checks in at the restaurant, he can review the
        restaurant's details.
      </>
    ),
    image_name: ["phone_workflow_01", "phone_results_list_02"],
  },
  {
    title: "Payment Workflow",
    text:
      "With the help of this functionality, the client can review his bill and pay it using the App.",
    image_name: ["phone_payment_workflow_01", "phone_payment_workflow_02"],
  },
  {
    title: "User Authentication",
    text:
      "This functionality lets the user sign up for the App, create his account and recover a password in case it is forgotten or lost.",
    image_name: ["phone_authentication_01", "phone_authentication_02"],
  },
  {
    title: "User Profile",
    text:
      "This app lets the user have his profile up to date and make all necessary changes there, for example, adding photos, editing personal information and reviewing profile settings.",
    image_name: ["phone_profile_01", "phone_profile_02"],
  },
  {
    title: "Payment methods management",
    text:
      "Clients can add the card to use for payments and review/update/delete payment methods.",
    image_name: ["phone_payment_management_01", "phone_payment_management_02"],
  },
  {
    title: "List of restaurants",
    text:
      "A user can review the list of restaurants/recently visited/information about the restaurant and select the most appropriate one to visit.",
    image_name: ["phone_results_list_01", "phone_results_list_02"],
  },
]

const SoftwareFunctionality = () => {
  return (
    <section className={styles.softwareFunctionalityBlock}>
      <div className={styles.container}>
        <h2>Software functionality</h2>
        <div>
          {FUNCTIONALITY_LIST.map(item => (
            <div key={item.title} className={styles.functionalityItem}>
              <div className={styles.softwareFunctionalityText}>
                <h3>{item.title}</h3>
                <p>{item.text}</p>
              </div>
              <div className={styles.functionalityImages}>
                {item.image_name.map((imageName, index) => (
                  <div key={index}>
                    <picture>
                      <source
                        media="(max-width: 428px)"
                        srcSet={`${IMG.CHEQMATE}/img/${imageName}_156.avif,	${IMG.CHEQMATE}/img/${imageName}_312.avif 2x, 	 ${IMG.CHEQMATE}/img/${imageName}_468.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(max-width: 428px)"
                        srcSet={`${IMG.CHEQMATE}/img/${imageName}_156.webp,	${IMG.CHEQMATE}/img/${imageName}_312.webp 2x, 	 ${IMG.CHEQMATE}/img/${imageName}_468.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(max-width: 428px)"
                        srcSet={`${IMG.CHEQMATE}/img/${imageName}_156.png,		${IMG.CHEQMATE}/img/${imageName}_312.png 2x,		${IMG.CHEQMATE}/img/${imageName}_468.png 3x`}
                      />
                      <source
                        media="(min-width: 429px)"
                        srcSet={`${IMG.CHEQMATE}/img/${imageName}_272.avif,	${IMG.CHEQMATE}/img/${imageName}_544.avif 2x,	${IMG.CHEQMATE}/img/${imageName}_816.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(min-width: 429px)"
                        srcSet={`${IMG.CHEQMATE}/img/${imageName}_272.webp,	${IMG.CHEQMATE}/img/${imageName}_544.webp 2x,	${IMG.CHEQMATE}/img/${imageName}_816.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(min-width: 429px)"
                        srcSet={`${IMG.CHEQMATE}/img/${imageName}_272.png,		${IMG.CHEQMATE}/img/${imageName}_544.png 2x,  	${IMG.CHEQMATE}/img/${imageName}_816.png 3x`}
                      />
                      <img
                        src={`${IMG.CHEQMATE}/img/${imageName}_816.png`}
                        alt={item.title}
                        width="138"
                        height="288"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SoftwareFunctionality
