import React from "react"
import cx from "classnames"

import Layout from "../components/layout"
import * as cheqmateStyles from "../assets/styles/cheqmate.module.scss"
import SoftwareDevelopment from "../components/cheqmate/SoftwareDevelopment"
import SoftwareFunctionality from "../components/cheqmate/SoftwareFunctionality"
import { IMG } from "../const"

const Cheqmate = () => {
  return (
    <Layout pageTitle="Cheqmate">
      {() => {
        return (
          <>
            <main>
              <article className={cheqmateStyles.cheqmateArticle}>
                <div className={cheqmateStyles.topBlock}>
                  <div
                    className={cx(
                      cheqmateStyles.flexBlock,
                      cheqmateStyles.cheqmateContainer
                    )}
                  >
                    <div>
                      <div className={cheqmateStyles.textBlock}>
                        <h1 data-title="Cheqmate">Cheqmate</h1>
                        <p>
                          Cheqmate helps to replace self-service kiosks at
                          restaurants. Instead of using the kiosks at the table,
                          clients can check in to the restaurant and the system
                          takes them to Cheqmate. The client then enters the
                          table number and orders the food from their phone.
                          Moreover, the clients have the possibility to pay with
                          phone, as well.
                        </p>
                      </div>
                      <div>
                        <picture>
                          <source
                            media="(max-width: 428px)"
                            srcSet={`${IMG.CHEQMATE}/img/macbook_382.avif,	${IMG.CHEQMATE}/img/macbook_764.avif 2x, 	 ${IMG.CHEQMATE}/img/macbook_1146.avif 3x`}
                            type="image/avif"
                          />
                          <source
                            media="(max-width: 428px)"
                            srcSet={`${IMG.CHEQMATE}/img/macbook_382.webp,	${IMG.CHEQMATE}/img/macbook_764.webp 2x, 	 ${IMG.CHEQMATE}/img/macbook_1146.webp 3x`}
                            type="image/webp"
                          />
                          <source
                            media="(max-width: 428px)"
                            srcSet={`${IMG.CHEQMATE}/img/macbook_382.png,		${IMG.CHEQMATE}/img/macbook_764.png 2x,		${IMG.CHEQMATE}/img/macbook_1146.png 3x`}
                          />
                          <source
                            media="(min-width: 429px)"
                            srcSet={`${IMG.CHEQMATE}/img/macbook_764.avif,	${IMG.CHEQMATE}/img/macbook_1408.avif 2x,	${IMG.CHEQMATE}/img/macbook_2112.avif 3x`}
                            type="image/avif"
                          />
                          <source
                            media="(min-width: 429px)"
                            srcSet={`${IMG.CHEQMATE}/img/macbook_764.webp,	${IMG.CHEQMATE}/img/macbook_1408.webp 2x,	${IMG.CHEQMATE}/img/macbook_2112.webp 3x`}
                            type="image/webp"
                          />
                          <source
                            media="(min-width: 429px)"
                            srcSet={`${IMG.CHEQMATE}/img/macbook_764.png,		${IMG.CHEQMATE}/img/macbook_1408.png 2x,  	${IMG.CHEQMATE}/img/macbook_2112.png 3x`}
                          />
                          <img
                            src={`${IMG.CHEQMATE}/img/macbook_1408.png`}
                            alt="Cheqmate App Welcome page"
                            width="288"
                            height="181"
                            loading="lazy"
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                  <div
                    className={cx(
                      cheqmateStyles.textWrapper,
                      cheqmateStyles.cheqmateContainer,
                      cheqmateStyles.topBlockLead
                    )}
                  >
                    <div className={cheqmateStyles.textBackground}>
                      <h2>
                        Client’s <strong>background</strong>
                      </h2>
                      <p>
                        A client started cooperation with LaSoft in 2020. The
                        project deals with the food and drink industry. Its main
                        goal is to allow visitors to pay bills in the cafés or
                        restaurants using the app.
                      </p>
                    </div>
                  </div>
                </div>
                <section className={cheqmateStyles.firstBlock}>
                  <div className={cheqmateStyles.cheqmateContainer}>
                    <div>
                      <div className={cheqmateStyles.imageBlock}>
                        <picture>
                          <source
                            srcSet={`${IMG.CHEQMATE}/img/phone_01_264.avif,	${IMG.CHEQMATE}/img/phone_01_528.avif 2x, ${IMG.CHEQMATE}/img/phone_01_792.avif 3x`}
                            type="image/avif"
                          />
                          <source
                            srcSet={`${IMG.CHEQMATE}/img/phone_01_264.webp,	${IMG.CHEQMATE}/img/phone_01_528.webp 2x, ${IMG.CHEQMATE}/img/phone_01_792.webp 3x`}
                            type="image/webp"
                          />
                          <source
                            srcSet={`${IMG.CHEQMATE}/img/phone_01_264.png, ${IMG.CHEQMATE}/img/phone_01_528.png 2x,${IMG.CHEQMATE}/img/phone_01_792.png 3x`}
                          />
                          <img
                            src={`${IMG.CHEQMATE}/img/phone_01_792.png`}
                            alt="Search nearby restaurants"
                            width="184"
                            height="382"
                            loading="lazy"
                          />
                        </picture>
                        <p>
                          After logging in, the user can check the list of
                          restaurants and filter them by:
                        </p>
                        <ul>
                          <li>nearby restaurants</li>
                          <li>recently visited</li>
                          <li>recently visited by friends</li>
                        </ul>
                      </div>
                      <div className={cheqmateStyles.imageBlock}>
                        <picture>
                          <source
                            srcSet={`${IMG.CHEQMATE}/img/phone_02_264.avif,	${IMG.CHEQMATE}/img/phone_02_528.avif 2x, ${IMG.CHEQMATE}/img/phone_02_792.avif 3x`}
                            type="image/avif"
                          />
                          <source
                            srcSet={`${IMG.CHEQMATE}/img/phone_02_264.webp,	${IMG.CHEQMATE}/img/phone_02_528.webp 2x, ${IMG.CHEQMATE}/img/phone_02_792.webp 3x`}
                            type="image/webp"
                          />
                          <source
                            srcSet={`${IMG.CHEQMATE}/img/phone_02_264.png, ${IMG.CHEQMATE}/img/phone_02_528.png 2x,${IMG.CHEQMATE}/img/phone_02_792.png 3x`}
                          />
                          <img
                            src={`${IMG.CHEQMATE}/img/phone_02_792.png`}
                            alt="Restaurant description"
                            width="184"
                            height="383"
                            loading="lazy"
                          />
                        </picture>
                        <p>
                          The User has to check in to the restaurant after, and
                          the waiter will then be able to find the user in his
                          POS system and add his order into the system. As a
                          result, the user can view the bill.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
                <section className={cheqmateStyles.secondBlock}>
                  <div
                    className={cx(
                      cheqmateStyles.cheqmateContainer,
                      cheqmateStyles.flexBlock
                    )}
                  >
                    <div>
                      <div className={cheqmateStyles.possibilitiesText}>
                        <h3>
                          There is also a possibility to use a{" "}
                          <strong>discount</strong> and add a{" "}
                          <strong>tip</strong>
                        </h3>
                        <p>
                          The users can pay bills and split them with friends
                          they were having a meal with. Here are different ways
                          of splitting the bill.
                        </p>
                      </div>
                      <div className={cheqmateStyles.possibilitiesImage}>
                        <picture>
                          <source
                            media="(max-width: 428px)"
                            srcSet={`${IMG.CHEQMATE}/img/phone_03_208.avif,	${IMG.CHEQMATE}/img/phone_03_416.avif 2x, 	 ${IMG.CHEQMATE}/img/phone_03_672.avif 3x`}
                            type="image/avif"
                          />
                          <source
                            media="(max-width: 428px)"
                            srcSet={`${IMG.CHEQMATE}/img/phone_03_208.webp,	${IMG.CHEQMATE}/img/phone_03_416.webp 2x, 	 ${IMG.CHEQMATE}/img/phone_03_672.webp 3x`}
                            type="image/webp"
                          />
                          <source
                            media="(max-width: 428px)"
                            srcSet={`${IMG.CHEQMATE}/img/phone_03_208.png,		${IMG.CHEQMATE}/img/phone_03_416.png 2x,		${IMG.CHEQMATE}/img/phone_03_672.png 3x`}
                          />
                          <source
                            media="(min-width: 429px)"
                            srcSet={`${IMG.CHEQMATE}/img/phone_03_336.avif,	${IMG.CHEQMATE}/img/phone_03_672.avif 2x,	${IMG.CHEQMATE}/img/phone_03_1008.avif 3x`}
                            type="image/avif"
                          />
                          <source
                            media="(min-width: 429px)"
                            srcSet={`${IMG.CHEQMATE}/img/phone_03_336.webp,	${IMG.CHEQMATE}/img/phone_03_672.webp 2x,	${IMG.CHEQMATE}/img/phone_03_1008.webp 3x`}
                            type="image/webp"
                          />
                          <source
                            media="(min-width: 429px)"
                            srcSet={`${IMG.CHEQMATE}/img/phone_03_272.png,		${IMG.CHEQMATE}/img/phone_03_672.png 2x,  	${IMG.CHEQMATE}/img/phone_03_1008.png 3x`}
                          />
                          <img
                            src={`${IMG.CHEQMATE}/img/phone_03_1008.png`}
                            alt="Discounts and tips"
                            width="208"
                            height="434"
                            loading="lazy"
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                </section>
                <section className={cheqmateStyles.thirdBlock}>
                  <div className={cheqmateStyles.doubleImagesBlock}>
                    <div>
                      <h3>
                        The user can view the receipts <strong>history</strong>{" "}
                        and details
                      </h3>
                      <picture>
                        <source
                          media="(max-width: 428px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_04_156.avif,	${IMG.CHEQMATE}/img/phone_04_312.avif 2x, 	 ${IMG.CHEQMATE}/img/phone_04_500.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(max-width: 428px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_04_156.webp,	${IMG.CHEQMATE}/img/phone_04_312.webp 2x, 	 ${IMG.CHEQMATE}/img/phone_04_500.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(max-width: 428px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_04_156.png,		${IMG.CHEQMATE}/img/phone_04_312.png 2x,		${IMG.CHEQMATE}/img/phone_04_500.png 3x`}
                        />
                        <source
                          media="(min-width: 429px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_04_250.avif,	${IMG.CHEQMATE}/img/phone_04_500.avif 2x,	${IMG.CHEQMATE}/img/phone_04_750.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(min-width: 429px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_04_250.webp,	${IMG.CHEQMATE}/img/phone_04_500.webp 2x,	${IMG.CHEQMATE}/img/phone_04_750.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(min-width: 429px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_04_250.png,		${IMG.CHEQMATE}/img/phone_04_500.png 2x,  	${IMG.CHEQMATE}/img/phone_04_750.png 3x`}
                        />
                        <img
                          src={`${IMG.CHEQMATE}/img/phone_04_750.png`}
                          alt="Receipts history"
                          width="101"
                          height="286"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                    <div>
                      <picture>
                        <source
                          media="(max-width: 428px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_05_156.avif,	${IMG.CHEQMATE}/img/phone_05_312.avif 2x, 	 ${IMG.CHEQMATE}/img/phone_05_500.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(max-width: 428px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_05_156.webp,	${IMG.CHEQMATE}/img/phone_05_312.webp 2x, 	 ${IMG.CHEQMATE}/img/phone_05_500.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(max-width: 428px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_05_156.png,		${IMG.CHEQMATE}/img/phone_05_312.png 2x,		${IMG.CHEQMATE}/img/phone_05_500.png 3x`}
                        />
                        <source
                          media="(min-width: 429px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_05_250.avif,	${IMG.CHEQMATE}/img/phone_05_500.avif 2x,	${IMG.CHEQMATE}/img/phone_05_750.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(min-width: 429px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_05_250.webp,	${IMG.CHEQMATE}/img/phone_05_500.webp 2x,	${IMG.CHEQMATE}/img/phone_05_750.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(min-width: 429px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_05_250.png,		${IMG.CHEQMATE}/img/phone_05_500.png 2x,  	${IMG.CHEQMATE}/img/phone_05_750.png 3x`}
                        />
                        <img
                          src={`${IMG.CHEQMATE}/img/phone_05_750.png`}
                          alt="Friend List"
                          width="101"
                          height="286"
                          loading="lazy"
                        />
                      </picture>
                      <h3>
                        And <strong>add friends</strong> by sending them
                        requests
                      </h3>
                    </div>
                  </div>
                </section>
                <section className={cheqmateStyles.profileOptionsBlock}>
                  <div className={cheqmateStyles.profileOptions}>
                    <h2>The profile of each User includes the following:</h2>
                    <div>
                      <p>Profile information</p>
                      <picture>
                        <source
                          media="(max-width: 428px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_06_268.avif,	${IMG.CHEQMATE}/img/phone_06_416.avif 2x, 	 ${IMG.CHEQMATE}/img/phone_06_624.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(max-width: 428px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_06_268.webp,	${IMG.CHEQMATE}/img/phone_06_416.webp 2x, 	 ${IMG.CHEQMATE}/img/phone_06_624.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(max-width: 428px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_06_268.png,		${IMG.CHEQMATE}/img/phone_06_416.png 2x,		${IMG.CHEQMATE}/img/phone_06_624.png 3x`}
                        />
                        <source
                          media="(min-width: 429px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_06_268.avif,	${IMG.CHEQMATE}/img/phone_06_536.avif 2x,	${IMG.CHEQMATE}/img/phone_06_804.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(min-width: 429px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_06_268.webp,	${IMG.CHEQMATE}/img/phone_06_536.webp 2x,	${IMG.CHEQMATE}/img/phone_06_804.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(min-width: 429px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_06_268.png,		${IMG.CHEQMATE}/img/phone_06_536.png 2x,  	${IMG.CHEQMATE}/img/phone_06_804.png 3x`}
                        />
                        <img
                          src={`${IMG.CHEQMATE}/img/phone_06_804.png`}
                          alt="User Profile Information"
                          width="208"
                          height="432"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                    <div>
                      <p>Payment methods</p>
                      <picture>
                        <source
                          media="(max-width: 428px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_07_268.avif,	${IMG.CHEQMATE}/img/phone_07_416.avif 2x, 	 ${IMG.CHEQMATE}/img/phone_07_624.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(max-width: 428px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_07_268.webp,	${IMG.CHEQMATE}/img/phone_07_416.webp 2x, 	 ${IMG.CHEQMATE}/img/phone_07_624.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(max-width: 428px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_07_268.png,		${IMG.CHEQMATE}/img/phone_07_416.png 2x,		${IMG.CHEQMATE}/img/phone_07_624.png 3x`}
                        />
                        <source
                          media="(min-width: 429px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_07_268.avif,	${IMG.CHEQMATE}/img/phone_07_536.avif 2x,	${IMG.CHEQMATE}/img/phone_07_804.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(min-width: 429px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_07_268.webp,	${IMG.CHEQMATE}/img/phone_07_536.webp 2x,	${IMG.CHEQMATE}/img/phone_07_804.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(min-width: 429px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_07_268.png,		${IMG.CHEQMATE}/img/phone_07_536.png 2x,  	${IMG.CHEQMATE}/img/phone_07_804.png 3x`}
                        />
                        <img
                          src={`${IMG.CHEQMATE}/img/phone_07_804.png`}
                          alt="Payment Methods"
                          width="208"
                          height="432"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                    <div>
                      <p>App Settings</p>
                      <picture>
                        <source
                          media="(max-width: 428px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_08_268.avif,	${IMG.CHEQMATE}/img/phone_08_416.avif 2x, 	 ${IMG.CHEQMATE}/img/phone_08_624.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(max-width: 428px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_08_268.webp,	${IMG.CHEQMATE}/img/phone_08_416.webp 2x, 	 ${IMG.CHEQMATE}/img/phone_08_624.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(max-width: 428px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_08_268.png,		${IMG.CHEQMATE}/img/phone_08_416.png 2x,		${IMG.CHEQMATE}/img/phone_08_624.png 3x`}
                        />
                        <source
                          media="(min-width: 429px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_08_268.avif,	${IMG.CHEQMATE}/img/phone_08_536.avif 2x,	${IMG.CHEQMATE}/img/phone_08_804.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(min-width: 429px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_08_268.webp,	${IMG.CHEQMATE}/img/phone_08_536.webp 2x,	${IMG.CHEQMATE}/img/phone_08_804.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(min-width: 429px)"
                          srcSet={`${IMG.CHEQMATE}/img/phone_08_268.png,		${IMG.CHEQMATE}/img/phone_08_536.png 2x,  	${IMG.CHEQMATE}/img/phone_08_804.png 3x`}
                        />
                        <img
                          src={`${IMG.CHEQMATE}/img/phone_08_804.png`}
                          alt="App Settings"
                          width="208"
                          height="432"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                  </div>
                </section>
                <SoftwareDevelopment />
                <SoftwareFunctionality />
                <section className={cheqmateStyles.valueBlock}>
                  <div className={cheqmateStyles.colored}>
                    <div>
                      <h2>
                        <strong>Value</strong> delivered
                      </h2>
                      <div className={cheqmateStyles.valueText}>
                        <div>
                          <p>
                            Released frontend development with whole
                            functionality
                          </p>
                        </div>
                        <div>
                          <p>
                            Released backend development of the application on
                            such functionalities as:
                          </p>
                          <ul>
                            <li>database processing</li>
                            <li>finance module</li>
                            <li>order processing module.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className={cheqmateStyles.resultsSection}>
                  <div className={cheqmateStyles.resultsBlock}>
                    <div>
                      <h3>Results</h3>
                      <p>
                        We go live with a complete prototype. So, the clients
                        can check whether everything is okay and test on
                        existing restaurants and their clients. Also, the base
                        of restaurants and clients is actively growing.
                      </p>
                    </div>
                    <div>
                      <picture>
                        <source
                          media="(max-width: 428px)"
                          srcSet={`${IMG.CHEQMATE}/img/macbook_results_382.avif,	${IMG.CHEQMATE}/img/macbook_results_764.avif 2x, 	 ${IMG.CHEQMATE}/img/macbook_results_1146.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(max-width: 428px)"
                          srcSet={`${IMG.CHEQMATE}/img/macbook_results_382.webp,	${IMG.CHEQMATE}/img/macbook_results_764.webp 2x, 	 ${IMG.CHEQMATE}/img/macbook_results_1146.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(max-width: 428px)"
                          srcSet={`${IMG.CHEQMATE}/img/macbook_results_382.png,		${IMG.CHEQMATE}/img/macbook_results_764.png 2x,		${IMG.CHEQMATE}/img/macbook_results_1146.png 3x`}
                        />
                        <source
                          media="(min-width: 429px)"
                          srcSet={`${IMG.CHEQMATE}/img/macbook_results_764.avif,	${IMG.CHEQMATE}/img/macbook_results_1408.avif 2x,	${IMG.CHEQMATE}/img/macbook_results_2112.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(min-width: 429px)"
                          srcSet={`${IMG.CHEQMATE}/img/macbook_results_764.webp,	${IMG.CHEQMATE}/img/macbook_results_1408.webp 2x,	${IMG.CHEQMATE}/img/macbook_results_2112.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(min-width: 429px)"
                          srcSet={`${IMG.CHEQMATE}/img/macbook_results_764.png,		${IMG.CHEQMATE}/img/macbook_results_1408.png 2x,  	${IMG.CHEQMATE}/img/macbook_results_2112.png 3x`}
                        />
                        <img
                          src={`${IMG.CHEQMATE}/img/macbook_results_1408.png`}
                          alt="Dowload Page"
                          width="288"
                          height="185"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                  </div>
                </section>
              </article>
            </main>
          </>
        )
      }}
    </Layout>
  )
}

export default Cheqmate
